<template>
    <div class="customer">
        <div class="search-box">
            <div class="search-one">
                <div class="left">
                    <p v-if="routePath == '/alimail'">
                        <span>排序方式</span>
                        <el-radio-group
                            size="small"
                            v-model="orderBy"
                            @change="
                                (currentPage = 1),
                                    (pagesize = 20),
                                    getTableData()
                            "
                        >
                            <el-radio-button label="place_order_time"
                                >交易时间</el-radio-button
                            >
                            <el-radio-button label="report_expire_time"
                                >到期时间</el-radio-button
                            >
                        </el-radio-group>
                    </p>
                    <p>
                        <Department
                            ref="department"
                            :departmentWidth="150"
                            :adminWidth="150"
                            :depSearch="btnP.depSearch"
                            :searchUser="btnP.searchUser"
                            @searchData="searchData"
                        ></Department>
                    </p>
                    <!-- <p>
                        <span v-show="btnP.depSearch">部门</span>
                        <el-select
                            v-show="btnP.depSearch"
                            filterable
                            v-model="dempVal"
                            size="small"
                            style="width: 150px"
                            clearable
                            placeholder="全部"
                            @change="changeDepartmentId()"
                        >
                            <el-option
                                v-for="item in dempData"
                                :key="item.id"
                                :label="item.departmentName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </p>
                    <p>
                        <span v-show="btnP.searchUser">成员</span>
                        <el-select
                            v-show="btnP.searchUser"
                            v-model="userVal"
                            size="small"
                            filterable
                            style="width: 150px"
                            clearable
                            placeholder="全部"
                            @change="
                                (currentPage = 1),
                                    (pagesize = 20),
                                    getTableData()
                            "
                        >
                            <el-option
                                v-for="item in userList"
                                :key="item.id"
                                :label="item.nickName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </p> -->
                    <p>
                        <span>到期时间</span>
                        <el-radio-group
                            v-if="routePath == '/alimailbox'"
                            v-model="dateChoice"
                            style="margin-right: 10px"
                            size="small"
                        >
                            <el-radio-button
                                label="3个月内"
                                @click.native.prevent="clickitem('3个月内')"
                            ></el-radio-button>
                            <el-radio-button
                                label="半年内"
                                @click.native.prevent="clickitem('半年内')"
                            ></el-radio-button>
                            <el-radio-button
                                label="一年内"
                                @click.native.prevent="clickitem('一年内')"
                            ></el-radio-button>
                        </el-radio-group>
                        <el-date-picker
                            size="small"
                            v-model="createDate"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width: 230px"
                            clearable
                            @change="expireTime"
                        >
                        </el-date-picker>
                    </p>
                    <p>
                        <span>查询</span>

                        <el-input
                            size="small"
                            style="width: 180px"
                            placeholder="公司名称、客户名称"
                            v-model.trim="fieldVal"
                            clearable
                        >
                        </el-input>
                        <el-button
                            @click="
                                (currentPage = 1),
                                    (pagesize = 20),
                                    getTableData()
                            "
                            type="primary"
                            size="small"
                            icon="el-icon-search"
                            style="border-color: #2370eb"
                        ></el-button>
                    </p>
                </div>
                <p style="width: 200px">
                    <el-button
                        type="primary"
                        style="
                            margin-right: 12px;
                            border-color: #2370eb;
                            background: #2370eb;
                        "
                        size="small"
                        @click="
                            (currentPage = 1), (pagesize = 20), getTableData()
                        "
                        >查询</el-button
                    >
                    <el-button
                        @click="
                            (currentPage = 1), (pagesize = 20), searchEmpty()
                        "
                        type="primary"
                        style="
                            margin: 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                            margin-right: 12px;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                </p>
            </div>
        </div>
        <div class="table-title">
            <div style="font-size: 14px; color: #333">已成交客户列表&nbsp;&nbsp;&nbsp;&nbsp; 注:员工名称前<span style="color: #ff0000;">●</span>表示从别人那里继承的数据</div>
            <span>
                <button class="add" v-if="btnP.add" @click="onAdd()">
                    <i class="el-icon-plus"></i> 新增客户
                </button>
            </span>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="10"> </el-table-column>
            <el-table-column label="产品ID" width="170" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span type="text">{{ scope.row.identification }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="公司名称" width="239" show-overflow-tooltip>
                <template slot="header">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;公司名称</div>
                </template>
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        style="color: #2370eb"
                        @click.stop="onCopy(scope.row.companyName)"
                        v-if="scope.row.companyName"
                        class="iconfont icon-fuzhi1"
                    ></el-button>
                    <el-button
                        type="text"
                        style="color: #2370eb; margin-left: 0"
                        @click="onPurchasedDetails(scope.row)"
                        >{{ scope.row.companyName }}</el-button
                    >
                    <!-- <span type="text">{{ scope.row.companyName }}</span> -->
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="客户" width="239" show-overflow-tooltip>
                <template slot="header">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;客户</div>
                </template>
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        style="color: #2370eb"
                        @click.stop="onCopy(scope.row.customerName)"
                        v-if="scope.row.customerName"
                        class="iconfont icon-fuzhi1"
                    ></el-button>
                    <el-button
                        type="text"
                        style="color: #2370eb"
                        @click="onRelationDetails(scope.row)"
                        >{{ scope.row.customerName }}</el-button
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="订单归属"
                width="63"
                prop="saleAdminName"
                show-overflow-tooltip
            >
            <template slot-scope="scope">
                    <span v-if="scope.row.inherit == 2" style="color: #ff0000;">●</span>
                    <span>
                    {{
                        scope.row.saleAdminName
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="客户归属"
                prop="initAdminName"
                width="63"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span>
                    {{
                        scope.row.initAdminName
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="开通时间" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.reportTime
                            ? scope.row.reportTime.slice(0, 10)
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="到期时间" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.reportExpireTime
                            ? scope.row.reportExpireTime.slice(0, 10)
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="domain"
                label="域名"
                show-overflow-tooltip
                width="145"
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="账号数"
                prop="number"
                width="50"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.number ? scope.row.number : '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <!-- <el-table-column
                label="年限"
                prop="userTerm"
                width="50"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.userTerm
                                ? Math.round(scope.row.userTerm)
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column> -->
            <el-table-column
                label="管理员账户"
                width="80"
                prop="adminAccount"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="邮箱版本" width="61" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.emailVersion == 0
                            ? '标准版'
                            : scope.row.emailVersion == 1
                            ? '尊享版'
                            : scope.row.emailVersion == 2
                            ? '集团版'
                            : ''
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="下单后台" width="85" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.libraryName ? scope.row.libraryName : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="操作"
                width="80"
                show-overflow-tooltip
                fixed="right"
            >
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        style="color: #2370eb"
                        v-if="btnP.upData"
                        @click="onUpData(scope.row)"
                        >分配</el-button
                    >
                </template>
            </el-table-column>

            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 新建 -->
        <el-drawer
            :visible.sync="drawer"
            :direction="direction"
            :before-close="handleClose"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    新增客户
                </div>
            </template>
            <Add @close="handleClose" ref="add" />
        </el-drawer>
        <!-- 编辑 -->
        <el-drawer
            :visible.sync="drawerEdit"
            :direction="direction"
            :before-close="handleClose"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    编辑客户
                </div>
            </template>
            <Edit @close="handleClose" ref="edit" />
        </el-drawer>
    </div>
</template>
<script>
import { closedCusList } from '@/api/closedCus/closedCus';
import Add from './add.vue';
import Edit from './edit.vue';
import { getCustomerId } from '@/api/user/ae/customer';
import { roleList } from '@/api/customer/customer';
import { departmentList } from '@/api/admin/framework/framework.js';
import Department from '../../../components/DepartmentAll';
export default {
    components: {
        Add,
        Edit,
        Department,
    },
    data() {
        return {
            orderBy: 'place_order_time',
            fieldVal: '',
            dateChoice: '3个月内',
            createDate: [],
            userList: [],
            dempData: [],
            dempVal: '',
            userVal: '',
            tableData: [],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            btnP: {},
            drawer: false,
            drawerEdit: false,
            direction: 'rtl',
            pageData: {},
            isPage: false,
            routePath: this.$route.path,
            field: false,
        };
    },
    mounted() {
        if (sessionStorage.getItem('pageData')) {
            this.currentPage = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageNum;
            this.pagesize = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageSize;
            // console.log(JSON.parse(sessionStorage.getItem('pageData')));
            this.fieldVal = JSON.parse(
                sessionStorage.getItem('pageData')
            ).param.name;
            this.$refs.department.$data.adminId = this.userVal = JSON.parse(
                sessionStorage.getItem('pageData')
            ).param.adminId;
            this.$refs.department.$data.departmentId = this.dempVal =
                JSON.parse(
                    sessionStorage.getItem('pageData')
                ).param.departmentId;
            this.dateChoice = JSON.parse(
                sessionStorage.getItem('pageData')
            ).dateChoice;
            this.createDate = JSON.parse(
                sessionStorage.getItem('pageData')
            ).createDate;
            this.$refs.department.getUserList(this.dempVal);
            sessionStorage.removeItem('pageData');
            this.isPage = true;
        } else {
            this.isPage = true;
        }
        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            if (sessionStorage.getItem('searchCompanyName')) {
                this.fieldVal = sessionStorage.getItem('searchCompanyName');
            }
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            if (!btn.depSearch && this.btnP.searchUser) {
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }
            if (btn.depSearch) {
                this.getDempData();
                this.field = true;
            }
            this.getTableData();
        },
        searchData(data) {
            this.pagesize = 20;
            this.pageNum = 1;
            this.dempVal = data.departmentId;
            this.userVal = data.adminId;
            this.getTableData();
        },
        getDempData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.dempData = res.data;
                this.$refs.department.getData(this.btnP, res.data);
            });
        },

        // 单选时间
        clickitem(e) {
            this.dateChoice = e;
            this.createDate = [];
            this.getTableData();
        },
        // 时间输入框变化
        expireTime() {
            this.currentPage = 1;
            this.pagesize = 20;
            if (this.createDate && this.createDate.length > 0) {
                this.dateChoice = '';
            } else {
                this.dateChoice = '3个月内';
            }
            this.getTableData();
        },
        getTableData() {
            if (this.routePath == '/alimailbox') {
                this.orderBy = 'report_expire_time';
            }
            let data = {
                param: {
                    type: 2,
                    orderBy: this.orderBy,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (this.fieldVal != '') {
                data.param.name = this.fieldVal;
            }
            if (!this.btnP.depSearch && this.btnP.searchUser) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
                if (this.userVal != '') {
                    data.param.adminId = this.userVal;
                }
            }
            if (this.btnP.depSearch && !this.btnP.searchUser) {
                data.param.departmentId = this.dempVal;
            }
            if (this.btnP.depSearch && this.btnP.searchUser) {
                data.param.departmentId = this.dempVal;
                data.param.adminId = this.userVal;
            }
            if (!this.btnP.depSearch && !this.btnP.searchUser) {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (this.dateChoice != '' && this.routePath == '/alimailbox') {
                let date = new Date();
                data.param.startDate = this.$searchTime.getNextDate(date, 0);
                this.createDate = [];
                if (this.dateChoice == '3个月内') {
                    data.param.endDate = this.$searchTime.getThreeMothTime(3);
                } else if (this.dateChoice == '半年内') {
                    data.param.endDate = this.$searchTime.getThreeMothTime(6);
                } else if (this.dateChoice == '一年内') {
                    data.param.endDate = this.$searchTime.getThreeMothTime(12);
                }
            }
            if (this.createDate && this.createDate.length > 0) {
                data.param.startDate = this.createDate[0];
                data.param.endDate = this.createDate[1];
            }
            this.pageData = {
                ...data,
                createDate: this.createDate,
                dateChoice: this.dateChoice,
            };
            closedCusList(data).then((res) => {
                // console.log(res.data);
                res.data.list.forEach((item) => {
                    item.userNumber = 0;
                    item.userTerm = 0;
                    item.minOpenTime = 0;
                    item.maxExpireTime = 0;
                    item.recoveryTime = this.$searchTime.getNextDate(
                        item.reportExpireTime,
                        -90
                    );
                    if (
                        item.orderPlanBOList &&
                        item.orderPlanBOList.length > 0
                    ) {
                        item.minOpenTime = item.orderPlanBOList[0].openTime;

                        item.maxExpireTime = item.orderPlanBOList[0].expireTime;
                        item.orderPlanBOList.forEach((itm) => {
                            if (
                                new Date().getTime() <=
                                    new Date(itm.expireTime).getTime() &&
                                new Date().getTime() >=
                                    new Date(itm.openTime).getTime()
                            ) {
                                item.userNumber += itm.number;
                            } else if (
                                new Date().getTime() >
                                new Date(itm.expireTime).getTime()
                            ) {
                                item.userNumber += itm.number;
                            }
                            if (itm.planType != 1) {
                                item.userTerm += itm.term;
                            }
                            if (
                                new Date(item.minOpenTime).getTime() >=
                                new Date(itm.openTime).getTime()
                            ) {
                                item.minOpenTime = itm.openTime;
                            }
                            if (
                                new Date(item.maxExpireTime).getTime() <=
                                new Date(itm.expireTime).getTime()
                            ) {
                                item.maxExpireTime = itm.expireTime;
                            }
                        });
                    } else {
                        item.userNumber = item.number;
                        if (item.reportExpireTime && item.reportTime) {
                            let date =
                                new Date(item.reportExpireTime).getTime() -
                                new Date(item.reportTime).getTime();
                            let leftsecond = parseInt(date / 1000);
                            item.userTerm =
                                Math.floor(leftsecond / (60 * 60 * 24)) / 365;
                        }
                    }
                });
                this.total = res.data.total;
                this.tableData = res.data.list;
            });
        },

        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getTableData();
        },

        // 详情
        onDetails(row) {
            let btn = JSON.parse(sessionStorage.getItem('isButArr'));
            let type = false;
            for (let i in btn) {
                if (btn[i].route == 'upData') {
                    type = true;
                    break;
                }
            }

            if (type) {
                this.$router.push({
                    path: '/closedcusDetails',
                    query: {
                        type: type,
                        id: row.id,
                    },
                });
            } else {
                this.$router.push({
                    path: '/closedcusDetails',
                    query: {
                        id: row.id,
                    },
                });
            }
            let data = {
                param: {
                    id: row.customerId,
                },
            };
            getCustomerId(data).then((res) => {
                this.$choiceLabel.commit('getType', '2');
                sessionStorage.setItem(
                    'pageData',
                    JSON.stringify(this.pageData)
                );
            });
        },
        onPurchasedDetails(row) {
            sessionStorage.setItem('pageData', JSON.stringify(this.pageData));
            this.$router.push({
                path: '/purchasedDetails',
                query: {
                    id: row.id,
                    type: 2,
                    isField: this.field,
                },
            });
        },
        onRelationDetails(row) {
            let btn = JSON.parse(sessionStorage.getItem('isButArr'));
            let type = false;
            for (let i in btn) {
                if (btn[i].route == 'upData') {
                    type = true;
                    break;
                }
            }
            let data = {
                param: {
                    id: row.customerId,
                },
            };
            getCustomerId(data).then((res) => {
                this.$formDetails.commit(
                    'detailsData',
                    JSON.stringify(res.data)
                );
                this.$choiceLabel.commit('getType', '2');
                sessionStorage.setItem(
                    'pageData',
                    JSON.stringify(this.pageData)
                );
                if (type) {
                    this.$router.push({
                        path: '/customerdetails',
                        query: {
                            type: type,
                            isPurchased: true,
                        },
                    });
                } else {
                    this.$router.push({
                        path: '/customerdetails',
                        query: {
                            isPurchased: true,
                        },
                    });
                }
            });
        },
        // 新增
        onAdd() {
            this.drawer = true;
            setTimeout(() => {
                this.$refs.add.getRelation();
                this.$refs.add.accountList();
            });
        },

        // 编辑
        onUpData(row) {
            this.drawerEdit = true;
            setTimeout(() => {
                this.$refs.edit.getRelation(row);
                this.$refs.edit.accountList();
            });
        },
        handleClose() {
            this.drawer = false;
            this.drawerEdit = false;
            this.getTableData();
        },
        // 复制
        onCopy(item) {
            let url = item;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
        // 清空
        searchEmpty() {
            this.fieldVal = '';
            this.createDate = [];
            this.getTableData();
        },
    },
    destroyed() {
        sessionStorage.removeItem('searchCompanyName');
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.customer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 0 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .left {
                display: flex;
                flex-wrap: wrap;
                p {
                    margin-bottom: 12px;
                }
                button {
                    background: #2370eb;
                    border-radius: 2px;
                    margin-right: 16px;
                }
            }
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        .add {
            border-color: #d9d9d9;
            color: #333333;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }

        button {
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
        }

        button:hover {
            background: #2370eb;
            color: #ffffff;
            border-color: #2370eb;
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Times {
        display: inline-block;
        width: 20px;
        line-height: 16px;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        background: #ff760f;
        border-radius: 63px 63px 63px 1px;
        text-align: center;
    }
    .Timess {
        background: #ff9385;
        margin-left: 3px;
    }
}
</style>
